.withripple {
    position: relative;
}
.ripple-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
    pointer-events: none;
}
.ripple {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
    border-radius: 100%;
    background-color: rgba(0,0,0,0.05);
    transform: scale(1);
    transform-origin: 50%;
    opacity: 0;
    pointer-events: none;
}
.ripple.ripple-on {
    transition: opacity 0.15s ease-in 0s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
    opacity: 0.15;
}
.ripple.ripple-out {
    transition: opacity 0.1s linear 0s !important;
    opacity: 0;
}
